.zt-webshop-cart-checkout {
    &__gui {
        &__widget {
            div {
                border-radius: 20px;
                // max-width: 728px;
                text-align: center;
                margin: 0 auto;
            }
        }
    }

}