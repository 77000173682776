body {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}


h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500; //Default == semibold
    font-size: 30px;
    line-height: 33px;

    &.bold {
        font-weight: bold;
    }

    &.light {
        font-weight: 300;
    }

    @media (min-width: 670px) {
        font-size: 36px;
        line-height: 45px;
    }

    @media (min-width: 960px) {
        font-size: 40px;
        line-height: 40px;
    }

    @media (min-width: 1280px) {
        font-size: 42px;
        line-height: 60px;
    }

    @media (min-width: 1920px) {
        font-size: 48px;
        line-height: 70px;
    }
}


h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500; //Default == semibold
    font-size: 26px;
    line-height: 29px;

    &.bold {
        font-weight: bold;
    }

    &.light {
        font-weight: 300;
    }

    @media (min-width: 670px) {
        font-size: 30px;
        line-height: 38px;
    }

    @media (min-width: 960px) {
        font-size: 36px;
        line-height: 50px;
    }

    @media (min-width: 1280px) {
        font-size: 38px;
        line-height: 55px;
    }

    @media (min-width: 1920px) {
        font-size: 44px;
        line-height: 68px;  
    }
}

h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500; //Default == semibold
    font-size: 24px;
    line-height: 27px;

    &.bold {
        font-weight: bold;
    }

    &.light {
        font-weight: 300;
    }

    @media (min-width: 670px) {
        font-size: 26px;
        line-height: 33px;
    }

    @media (min-width: 960px) {
        font-size: 28px;
        line-height: 40px;;
    }

    @media (min-width: 1280px) {
        font-size: 32px;
        line-height: 45px;
    }

    @media (min-width: 1920px) {
        font-size: 36px;
        line-height: 55px;
    }    
}

h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600; //Default == semibold
    font-size: 22px;
    line-height: 25px;

    &.bold {
        font-weight: bold;
    }

    &.light {
        font-weight: 300;
    }

    @media (min-width: 670px) {
        font-size: 24px;
        line-height: 30px;
    }

    @media (min-width: 960px) {
        font-size: 24px;
        line-height: 30px;
    }

    @media (min-width: 1280px) {
        font-size: 28px;
        line-height: 35px;
    }

    @media (min-width: 1920px) {
        font-size: 28px;
        line-height: 35px;
    }    
}

.zt-nav {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    &.bold {
        font-weight: bold;
    }

    &.semibold {
        font-weight: 600;
    }

    &.light {
        font-weight: normal;
    }

    @media (min-width: 670px) {
        font-size: 18px;
        line-height: 22px;
    }

    @media (min-width: 960px) {
        font-size: 18px;
        line-height: 22px;
    }

    @media (min-width: 1280px) {
        font-size: 18px;
        line-height: 22px;
    }

    @media (min-width: 1920px) {
        font-size: 18px;
        line-height: 22px;
    }     
}

.zt-p1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    &.bold {
        font-weight: bold;
    }

    &.gray {
        color: #7a7a7a;
    }

    @media (min-width: 670px) {
    }

    @media (min-width: 960px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (min-width: 1280px) {
    }

    @media (min-width: 1920px) {
    } 
    
}

.zt-p2 {
    /* p2 / Regular / 320 */
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    @media (min-width: 670px) {
    }

    @media (min-width: 960px) {
        font-size: 16px;
        line-height: 24px;
    }

    @media (min-width: 1280px) {
    }

    @media (min-width: 1920px) {
    }     
}

.zt-p3 {
    /* p3 / Regular / 320 */
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    &.bold {
        font-weight: bold;
    }    

    @media (min-width: 670px) {
    }

    @media (min-width: 960px) {
        font-size: 14px;
        line-height: 20px;
    }

    @media (min-width: 1280px) {
    }

    @media (min-width: 1920px) {
    }       
}