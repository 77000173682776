@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "./settings.scss";

@import "~bootstrap/scss/bootstrap";

body {
  background: black;
  color: #EEEEEE;
}

a {
  color: #eeeeee;
}

a:hover {
  color: #eeeeee;
}

ul, ul li {
  margin:0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.btn {
  background:#1b1b1b;
  border-radius: 8px;
  border: none;
  height: 32px;
}

.btn-primary:focus, .btn-primary.focus {
  background: #1b1b1b;
  border: none;
  box-shadow: none;
  svg {
    stroke: #fff;
    // fill: #fff;
  }
}

.btn-danger {
  background: #C00000;
  border-radius: 8px;
}

.red {
  border: 1px solid red;
}

.eventsfeed {
  margin-top: 40px;
}

.zt-page__transition-appear {
  opacity: 0;
  transform: scale(0.9);
}

.zt-page__transition-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.zt-modal-small {
  width: 400px;
}

.zt-modal-centered {
  .modal-content {
    background: none;
    border: none;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}