$grid-breakpoints: (
  xs: 0,
  sm: 667px,
  md: 900px,
  lg: 1366px,
  xl: 1920px
);

$container-max-widths: (
  xs: 400px,
  sm: 680px,
  md: 1060px,
  lg: 1400px,
  xl: 1800px
);
