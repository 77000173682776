$background-color: #1B1B1B; 
$base-color: #fff;
$hover-bg-color: #323232;
$hover-text-color: #fff;
$active-bg-color: #fff;
$active-text-color: #000;

$weekend-text-color: #7A7A7A;
$disabled-text-color: $weekend-text-color;

.react-calendar {
  width: 314px;
  max-width: 100%;
  background: $background-color;
  border-radius: 20px;
  border: 1px solid rgba(58, 58, 58, 0.4);


  /* Shadow */
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(74, 74, 74, 0.1), 0px 16px 32px rgba(18, 18, 18, 0.5), 0px 8px 8px rgba(74, 74, 74, 0.3), inset 0px 1px 0px rgba(255, 255, 255, 0.1);


  padding: 30px;

  &, & *, & *:before, & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }  

  button {
    margin: 0;
    border: 0;
    outline: none;
    border-radius: 8px;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    height: 48px;
    border-bottom: 1px solid #3A3A3A;
    margin-bottom: 21px;
    padding: 1px 0 0 0;
    line-height: 18px;

    button {
      min-width: 32px;
      background: none;
      color: $base-color;
      height: 22px;

      svg {
        margin-top: -1px;
      }


      &:enabled {
        &:hover, &:focus {
          background-color: $hover-bg-color;
        }
      }

      &[disabled] {
        opacity: 0.3;
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;
      margin-top: 3px;

      &__weekday {
        abbr {
          text-decoration: none;
        }
        // padding: @include 5em;
      }
    }

    &__days {
      &__day {
        background: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $base-color;        
        
        &--weekend {
          color: $weekend-text-color;
        }

        &--neighboringMonth {
          color: $weekend-text-color;
          opacity: 0.3;
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      background: none;
      color: $base-color;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 6px;

    + button {
      border-radius: 8px;
      border: 2px solid #1b1b1b;      
      height: 36px;
      line-height: 20px;
    }

    &:disabled {
      color: $weekend-text-color;
      // text-decoration: line-through;
      opacity: 0.3;
    }

    &:enabled {
      &:hover, &:focus {
        // background-color: $hover-bg-color;
        // color: $hover-text-color;
        color: $active-text-color;
        background: $active-bg-color;           
      }
    }


    &--now {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: $base-color;

      &:enabled {
        &:hover, &:focus {
        }
      }
    }


    &--active {
      background: $active-bg-color;
      border-radius: 8px;
      color: #181818;
      &:enabled {
        &:hover, &:focus {
          color: $active-text-color;
          background: $active-bg-color;     
        }
      }
    }
  }  

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        color: $active-text-color;
        background: $active-bg-color;
        &:hover {
          // color: $hover-text-color;
        }
      }
    }
  }  

}
